import { CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { MuiTheme } from "./components/MuiTheme";
import { ReCaptcha } from "./components/ReCaptcha";
import { UpgradeNotifications } from "./components/UpgradeNotifications";
import { ApolloContextProvider } from "./contexts/apollo";
import { AuthContextProvider as AuthContextProviderOld } from "./contexts/auth/AuthContextProvider";
import { DeviceContextProvider } from "./contexts/device";
import { DialogContextProvider } from "./contexts/dialog";

import { AuthContextProvider } from "../../2024/AuthContextProvider";
import "./Global.css";

const queryClient = new QueryClient();
const AppRoutes = React.lazy(() => import(/* webpackChunkName: "routes" */ "../1-auth"));

export function App() {
    return (
        <HelmetProvider>
            <Helmet defaultTitle="Stackables" titleTemplate="Stackables - %s" />
            <ErrorBoundary>
                <DeviceContextProvider>
                    <ReCaptcha>
                        <QueryClientProvider client={queryClient}>
                            <SnackbarProvider maxSnack={3}>
                                <BrowserRouter>
                                    <AuthContextProvider>
                                        <AuthContextProviderOld>
                                            <ApolloContextProvider>
                                                <MuiTheme>
                                                    <CssBaseline />
                                                    <UpgradeNotifications />
                                                    <DialogContextProvider>
                                                        <React.Suspense fallback={null}>
                                                            <AppRoutes />
                                                        </React.Suspense>
                                                    </DialogContextProvider>
                                                </MuiTheme>
                                            </ApolloContextProvider>
                                        </AuthContextProviderOld>
                                    </AuthContextProvider>
                                </BrowserRouter>
                            </SnackbarProvider>
                        </QueryClientProvider>
                    </ReCaptcha>
                </DeviceContextProvider>
            </ErrorBoundary>
        </HelmetProvider>
    );
}
