import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useMemo, useState, type PropsWithChildren } from "react";

export interface TokenDecoded {
    iss: string;
    jti: string;
    sub: string;
    iat: number;
    exp: number;
    email: string;
    csrf: string;
    counter: number;
    scopes: string[];
    ses?: number;
    td?: boolean;
}

export interface AuthContextInterface {
    isAuthenticated: boolean;
    token: TokenDecoded | undefined;
    logout: () => void;
    refresh: () => void;
    getTokenDigest: (force?: boolean) => Promise<string | undefined>;
}

export const AuthContext = React.createContext<AuthContextInterface>(undefined as any);

export const JWT_COOKIE_NAME = "access-token";

export function AuthContextProvider(props: PropsWithChildren) {
    const [contextTime, setContextTime] = useState<number>(Date.now());

    const contextValue = useMemo<AuthContextInterface>(() => {
        let tokenText = cookie.get(JWT_COOKIE_NAME);

        const tokenDecoded = (force?: boolean) => {
            const tokenString = force ? cookie.get(JWT_COOKIE_NAME) : tokenText;

            if (tokenString !== tokenText) {
                tokenText = tokenString;
            }

            if (!tokenString) {
                return undefined;
            }

            try {
                return jwtDecode<TokenDecoded>(tokenString);
            } catch (e) {
                return undefined;
            }
        };

        return {
            get isAuthenticated() {
                return tokenDecoded() !== undefined;
            },
            get token() {
                return tokenDecoded();
            },
            contextTime,
            logout: async () => {
                cookie.remove(JWT_COOKIE_NAME, {
                    domain: undefined,
                    // secure: true,
                    httpOnly: false,
                });

                contextValue.refresh();
            },
            refresh: () => {
                const tokenTextUpdated = cookie.get(JWT_COOKIE_NAME);
                if (tokenTextUpdated !== tokenText) {
                    setContextTime(Date.now());
                }
            },
            getTokenDigest: async (force?: boolean) => {
                try {
                    const token = tokenDecoded(force);

                    if (!token) {
                        return undefined;
                    }

                    return `Cookie/v3 ${token.csrf}`;
                } catch (e) {
                    return undefined;
                }
            },
        };
    }, [contextTime]);

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}
