import FingerprintJS, { GetResult } from "@fingerprintjs/fingerprintjs";
import { useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

export interface DeviceContextInterface {
    displayModePreference: "light" | "dark" | "auto";
    displayModeCurrent: "light" | "dark";
    getFingerprint(): Promise<GetResult | undefined>;
}

export const DeviceContext = React.createContext<any>(undefined);

interface DeviceContextProviderProps {
    children: React.ReactNode;
}

export function DeviceContextProvider(props: DeviceContextProviderProps) {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
        noSsr: true,
    });
    const [darkModePreference, setDarkModePreference] = useLocalStorage<
        "light" | "dark" | "auto"
    >("DeviceContextProvider.displayModePreference", "auto");
    const [darkModeCurrent, setDarkModeCurrent] = useState<"light" | "dark">(
        () => {
            if (darkModePreference === "auto") {
                // IAM domain is a special case as theme is driven by the redirecting browser
                if (window.location.hostname.startsWith("iam.stackables.")) {
                    return window.location.href.includes("dark")
                        ? "dark"
                        : "light";
                }

                // we want the same session to have the same preference so ...
                const sessionTheme = window.sessionStorage.getItem(
                    "prefers-color-scheme"
                );
                if (sessionTheme) {
                    return sessionTheme === "dark" ? "dark" : "light";
                }

                // if not set then lets store it for the session
                const current = prefersDarkMode ? "dark" : "light";
                window.sessionStorage.setItem("prefers-color-scheme", current);
                return current;
            }
            return darkModePreference;
        }
    );

    const fp = useRef(FingerprintJS.load({ monitoring: false }));

    const c = React.useMemo<DeviceContextInterface>(() => {
        return {
            get displayModeCurrent() {
                return darkModeCurrent;
            },
            set displayModeCurrent(val: "light" | "dark") {
                window.sessionStorage.setItem("prefers-color-scheme", val);
                setDarkModeCurrent(val);
            },
            async getFingerprint() {
                if (fp.current) {
                    const l = await fp.current;
                    return l.get();
                }
            },
            get displayModePreference() {
                return darkModePreference;
            },
            set displayModePreference(val: "light" | "dark" | "auto") {
                setDarkModePreference(val);
                if (val === "auto") {
                    setDarkModeCurrent(prefersDarkMode ? "dark" : "light");
                } else {
                    setDarkModeCurrent(val);
                }
            },
        };
    }, [
        darkModeCurrent,
        darkModePreference,
        setDarkModePreference,
        prefersDarkMode,
    ]);

    return (
        <DeviceContext.Provider value={c}>
            {props.children}
        </DeviceContext.Provider>
    );
}
