const hostname = window.location.hostname;
const subdomain = hostname.split(".")[0];
const basedomain = hostname.substring(subdomain.length + 1);

// const allowed = ["iam", "cloud"];
// if (!allowed.includes(subdomain)) {
//     throw new Error(`Subdomain ${subdomain} is not known`);
// }

export const dev = basedomain.endsWith("stackables.dev");
export const local = basedomain.endsWith("local.stackables.dev");
export const stackablesDomain = dev ? basedomain : "stackables.io";
export const reCaptchaKey = dev
    ? "6Lc7YQIaAAAAADQb5L4eIBMmYHHwhv5hYtXM5h6P"
    : "6LdtXgIaAAAAACRKE5zNaJoBVXlT-mr6WVF9WgiL";

export const serviceLocationIam = window.location.origin + "/iam";
export const serviceLocationCloud = window.location.origin;
export const serviceLocationData = window.location.protocol + "/graphql";
