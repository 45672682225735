import { Button } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";

export function UpgradeNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        offlineReady: [offlineReady, setOfflineReady],
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegistered(r) {
            console.log("SW Registered: " + r);
            r &&
                setInterval(
                    () => {
                        r.update();
                    },
                    60 * 60 * 1000
                );
        },
        onRegisterError(error) {
            console.log("SW registration error", error);
        },
    });

    const close = useCallback(
        (key: SnackbarKey) => {
            setOfflineReady(false);
            setNeedRefresh(false);
            closeSnackbar(key);
        },
        [setOfflineReady, setNeedRefresh, closeSnackbar]
    );

    useEffect(() => {
        if (needRefresh) {
            enqueueSnackbar("New version available", {
                preventDuplicate: true,
                persist: true,
                action: (key) => (
                    <div>
                        <Button onClick={() => updateServiceWorker(true)}>Install</Button>
                        <Button onClick={() => close(key)}>Dismiss</Button>
                    </div>
                ),
            });
        }
    }, [needRefresh, enqueueSnackbar, updateServiceWorker, close]);

    return null;
}
