import {
    Box,
    Dialog,
    Slide,
    SwipeableDrawer,
    useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
}));

interface ActionSheetProps {
    open: boolean;
    fullScreen?: boolean;
    onClose: React.ReactEventHandler<Record<string, unknown>>;
    onOpen: React.ReactEventHandler<Record<string, unknown>>;
    children: React.ReactNode;
}

export function ActionSheet(props: ActionSheetProps) {
    const standard = useMediaQuery("(min-width:600px)", { noSsr: true });

    if (standard || props.fullScreen) {
        return (
            <Dialog
                scroll="paper"
                open={props.open}
                onClose={props.onClose}
                fullScreen={props.fullScreen}
                TransitionComponent={standard ? undefined : Transition}
                keepMounted={true}
                PaperProps={{
                    style: {
                        minWidth: 500,
                    },
                }}
            >
                {props.children}
            </Dialog>
        );
    }

    return (
        <SwipeableDrawer
            anchor="bottom"
            role="dialog"
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            keepMounted={true}
            sx={{ zIndex: 1100 }}
            PaperProps={{
                sx: {
                    maxHeight: "calc(100vh - 100px)",
                    borderTopLeftRadius: 14,
                    borderTopRightRadius: 14,
                },
            }}
        >
            <Puller />
            {props.children}
        </SwipeableDrawer>
    );
}
