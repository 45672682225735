import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import React from "react";
import { errorHandler } from "../../../../lib/errorHandler";
import { dev } from "../../constants";
import "./ErrorBoundary.css";

export class ErrorBoundary extends React.Component<
    { children: React.ReactNode },
    { hasError: boolean; online: boolean | undefined; error: any }
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, online: undefined, error: undefined };
    }

    static getDerivedStateFromError(error: any) {
        console.error("getDerivedStateFromError", error);
        return { hasError: true, error };
    }

    async online(): Promise<boolean> {
        return new Promise((resolve) => {
            const isOnline = () => resolve(true);
            const isOffline = () => resolve(false);

            const xhr = new XMLHttpRequest();

            xhr.onerror = isOffline;
            xhr.ontimeout = isOffline;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                    if (xhr.status) {
                        isOnline();
                    } else {
                        isOffline();
                    }
                }
            };

            xhr.open("GET", `/online`);
            xhr.timeout = 1000;
            xhr.send();
        });
    }

    async recover() {
        window.sessionStorage.setItem(
            "outer-error-boundary",
            Date.now().toString()
        );
        if (navigator.serviceWorker && this.state.online) {
            try {
                const registrations =
                    await navigator.serviceWorker.getRegistrations();
                const unregister = registrations.map((registration) =>
                    registration.unregister()
                );
                await Promise.all(unregister);
                window.location.reload();
            } catch (error) {
                errorHandler.report(error);
                window.location.reload();
            }
        } else {
            window.location.reload();
        }
    }

    override componentDidCatch(error: any, errorInfo: any) {
        errorHandler.report(error);

        // For repeat errors, navigate user to home page after reloading
        const last = window.sessionStorage.getItem("outer-error-boundary");
        if (last) {
            if (Date.now() - parseInt(last) < 10_000) {
                window.sessionStorage.removeItem("outer-error-boundary");
                window.location.assign("/");
            }
        }

        this.online().then((online) => {
            this.setState((c) => {
                return {
                    ...c,
                    online,
                };
            });
        });
    }

    override render() {
        if (this.state.hasError) {
            return (
                <div className="center">
                    <CloudOffRoundedIcon sx={{ fontSize: 150 }} />
                    <h3>We have run into a little problem</h3>
                    {this.state.error && dev && (
                        <p>{this.state.error.message}</p>
                    )}
                    {this.state.online === true && (
                        <p>to recover hit the reload button below</p>
                    )}
                    {this.state.online === false && (
                        <p>
                            there seems to be a problem with your internet
                            connection
                        </p>
                    )}
                    <IconButton
                        onClick={() => {
                            this.recover();
                        }}
                    >
                        <RefreshIcon sx={{ fontSize: 56 }} />
                    </IconButton>
                </div>
            );
        }

        return this.props.children;
    }
}
