import * as React from "react";
import { useCallback, useRef } from "react";
import { createContext } from "use-context-selector";
import { useDebounceValue } from "usehooks-ts";

interface PortalProps {
    children: any;
}

interface PageReloadContextInterface {
    refresh: () => PromiseLike<any>;
    refreshable: boolean;
    loading: boolean;
    subscribe: (cb: () => PromiseLike<any>, loading: boolean) => void;
    unsubscribe: (cb: () => PromiseLike<any>) => void;
}

export const PageReloadContext = createContext<PageReloadContextInterface>(
    null as any
);

export function PageReloadContextProvider(props: PortalProps) {
    const [refreshable, setRefreshable] = React.useState(false);
    const listeners = useRef<Map<() => PromiseLike<any>, boolean>>(new Map());

    const [loading, setLoading] = useDebounceValue(true, 50);

    const handleOnRefresh = useCallback(async () => {
        const all = Array.from(listeners.current.keys()).map((c) => c());
        await Promise.all(all);
    }, []);

    const subscribe = useCallback(
        (cb: () => PromiseLike<any>, loading: boolean) => {
            listeners.current.set(cb, loading);
            derived();
        },
        []
    );

    const unsubscribe = useCallback((cb: () => PromiseLike<any>) => {
        listeners.current.delete(cb);
        derived();
    }, []);

    function derived() {
        setRefreshable(listeners.current.size > 0);
        setLoading(Array.from(listeners.current.values()).includes(true));
    }

    return (
        <PageReloadContext.Provider
            value={{
                subscribe,
                unsubscribe,
                refresh: handleOnRefresh,
                refreshable,
                loading: loading,
            }}
        >
            {props.children}
        </PageReloadContext.Provider>
    );
}
