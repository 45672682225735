export function isCredentialsApiSupported() {
    if (navigator.credentials) {
        return true;
    }
    return false;
}

export function isWebauthnApiSupported() {
    if (navigator.credentials && typeof PublicKeyCredential !== "undefined") {
        return true;
    }
    return false;
}
