import {
    ApolloClient,
    ApolloProvider,
    NormalizedCacheObject,
} from "@apollo/client";
import { useSnackbar } from "notistack";
import React from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { errorHandler } from "../../../../lib/errorHandler";
import { initApolloClient } from "./internal";

interface ApolloContextProviderProps {
    children: React.ReactNode;
}

export function ApolloContextProvider(props: ApolloContextProviderProps) {
    const auth = useAuthContext(false);
    const [client, setClient] =
        React.useState<ApolloClient<NormalizedCacheObject>>();
    const { enqueueSnackbar } = useSnackbar();

    const authTokenGetter = React.useCallback(async () => {
        return auth ? await auth.getTokenDigest() : undefined;
    }, [auth]);

    React.useEffect(() => {
        initApolloClient(authTokenGetter, enqueueSnackbar)
            .then((c) => setClient(c))
            .catch((e) => errorHandler.report(e));
    }, [authTokenGetter]);

    if (!client) return null;

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
