import React from "react";
import { DeviceContext, DeviceContextInterface } from "./DeviceContextProvider";

export function useDeviceContext(): DeviceContextInterface {
    const context = React.useContext(DeviceContext);

    if (!context) {
        throw new Error("DeviceContext not found");
    }

    return context;
}
