import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createContext } from "use-context-selector";
import { isCredentialsApiSupported } from "../../../../lib/passwords";
import { serviceLocationCloud, serviceLocationIam } from "../../constants";
import { getToken, getTokenDecoded, getTokenDigest, TokenDecoded } from "./internal";

export interface AuthContextInterface {
    isAuthenticated: boolean;
    isTrustedDevice: boolean;
    reauthSession: string | undefined;
    reload: () => void;
    logout: () => Promise<void>;
    getTokenDigest: (force?: boolean) => Promise<string | undefined>;
    getTokenDecoded<T>(): T | TokenDecoded | undefined;
}

export interface AuthContext2Interface {
    refreshingToken: boolean;
}

export const AuthContext = React.createContext<any>(undefined);
export const AuthContext2 = createContext<AuthContext2Interface>(null as any);

interface AuthContextProviderProps {
    children: React.ReactNode;
}

export function AuthContextProvider(props: AuthContextProviderProps) {
    const [tokenExists, setTokenExists] = React.useState(getToken() !== undefined);
    const [reauth, setReauth] = React.useState<string>();
    const [refreshingToken, setRefreshingToken] = React.useState(false);
    const refresher = React.useRef<Promise<Response> | undefined>();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const c = React.useMemo<AuthContextInterface>(() => {
        return {
            get isAuthenticated() {
                return tokenExists;
            },
            get reauthSession() {
                return reauth;
            },
            get isTrustedDevice() {
                const tokenDecoded = getTokenDecoded() as any;
                return tokenDecoded?.trusted === true;
            },
            getTokenDecoded,
            getTokenDigest: async (force = false) => {
                const current = await getTokenDigest();
                if (!current) {
                    return undefined;
                }

                const jwt = getTokenDecoded() as { exp: number };
                if (!jwt || !jwt.exp) {
                    return undefined;
                }

                // consider token valid when we have 5 minutes to live
                if (!force) {
                    if (jwt.exp - 15 * 60 > Date.now() / 1000) {
                        return current;
                    }
                }

                // Start single refresh instance
                if (!refresher.current) {
                    setRefreshingToken(true);
                    refresher.current = fetch(`${serviceLocationIam}/api/oauth2/sso`, {
                        credentials: "include",
                        headers: {
                            Authorization: current,
                        },
                    }).then((d) => {
                        setTimeout(() => {
                            setRefreshingToken(false);
                        }, 1000);

                        return d;
                    });
                }

                // if token is valid for more 1 minutes then dont block but use the old token
                if (jwt.exp - 1 * 60 > Date.now() / 1000) {
                    return current;
                }

                try {
                    const response = await refresher.current;
                    refresher.current = undefined;

                    const newState = getToken() !== undefined;
                    if (newState !== tokenExists) {
                        const status = await response.json();
                        if (status?.session) {
                            setReauth(`${status.session}`);
                        }

                        setTokenExists(newState);
                    }
                } catch (e) {
                    console.error("Error refreshing token", e);
                    refresher.current = undefined;
                    setRefreshingToken(false);
                }

                return getTokenDigest();
            },
            reload: () => {
                const newState = getToken() !== undefined;
                if (newState !== tokenExists) {
                    setTokenExists(newState);
                }
            },
            logout: async () => {
                await fetch(`${serviceLocationIam}/api/oauth2/logout`, {
                    credentials: "include",
                    headers: {
                        Authorization: String(await getTokenDigest()),
                    },
                });

                const newState = getToken() !== undefined;
                if (newState === false) {
                    window.sessionStorage.clear();
                    window.localStorage.clear();

                    if (isCredentialsApiSupported()) {
                        navigator.credentials.preventSilentAccess();
                    }

                    setTokenExists(newState);
                } else {
                    // show logout error
                    enqueueSnackbar("Logout failed", { variant: "error" });
                }
            },
        };
    }, [enqueueSnackbar, reauth, tokenExists]);

    useEffect(() => {
        if (location.hash.includes("nonce=")) {
            const nonce = location.hash.split("nonce=")[1];
            if (nonce && refresher.current === undefined) {
                refresher.current = fetch(`${serviceLocationCloud}/graphql`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({
                        query: "mutation NonceValidate($nonce: String!) {\n  response: nonceValidate(nonce: $nonce)\n}",
                        variables: {
                            nonce: nonce,
                        },
                    }),
                });

                refresher.current
                    .then(async (d) => {
                        const resp = await d.json();
                        if (resp?.data?.response) {
                            const newState = getToken() !== undefined;
                            setTokenExists(newState);
                        } else {
                            console.log("Error", resp);
                        }

                        refresher.current = undefined;
                        window.location.hash = "";
                    })
                    .catch((e) => {
                        console.log("Error", e);
                        refresher.current = undefined;
                    });
            }
        }
    }, [c.isAuthenticated, setTokenExists, getToken, location.hash]);

    return (
        <AuthContext2.Provider value={{ refreshingToken }}>
            <AuthContext.Provider value={c}>{props.children}</AuthContext.Provider>
        </AuthContext2.Provider>
    );
}
