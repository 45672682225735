// const handler = new StackdriverErrorReporter();

// handler.start({
//     key: "AIzaSyBh1BfYGcl4ZtJAkwYLj7-lIWEZq9srkig",
//     projectId: "stackables-prod",

//     // The following optional arguments can also be provided:

//     service: "content-manager",
//     // Name of the service reporting the error, defaults to 'web'.

//     // version: myServiceVersion,
//     // Version identifier of the service reporting the error.

//     // reportUncaughtExceptions: false
//     // Set to false to prevent reporting unhandled exceptions.

//     // reportUnhandledPromiseRejections: false
//     // Set to false to prevent reporting unhandled promise rejections.

//     disabled: window.location.hostname.includes("stackables.dev"),
//     // Set to true to not send error reports, this can be used when developing locally.

//     // context: {user: 'user1'}
//     // You can set the user later using setUser()
// });

export const errorHandler = {
    report: (error: string | Error | unknown) => {
        console.error(error);
    },
};

// errorHandler.report(new Error('asd'))
