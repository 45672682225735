import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import ApolloLinkTimeout from "apollo-link-timeout";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { EnqueueSnackbar } from "notistack";
import sha256 from "sha256";
import { errorHandler } from "../../../../lib/errorHandler";
import { serviceLocationCloud, serviceLocationIam } from "../../constants";

// import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
// import { createInstance } from 'localforage'

export async function initApolloClient(getToken: () => Promise<string | undefined>, enqueueSnackbar: EnqueueSnackbar) {
    // const storage = createInstance({
    //     name: 'Stackables',     // These fields
    //     storeName: 'kv',
    //     description: 'Simple key value storage for Stackables application',
    //     version: 1.0,      // are totally optional
    // })

    // FIXME: This needs to be fixed and autogenerated from the schema
    const cache = new InMemoryCache({
        possibleTypes: {
            UserLookupInfo: ["UserLookupEmail", "UserLookupOauth", "UserLookupPasskey"],
        },
    });

    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.sessionStorage),
        key: "apollo-default",
        serialize: true,
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation) => !!error,
        },
    });

    const presistedLink = createPersistedQueryLink({ sha256 });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach((e) => {
                let report = true;

                if (e.extensions?.code === "PERSISTED_QUERY_NOT_FOUND") {
                    report = false;
                }

                if (report) {
                    errorHandler.report(e.message);
                    enqueueSnackbar(e.message, { variant: "error" });
                }
            });

        if (networkError) {
            errorHandler.report(networkError);
            enqueueSnackbar(networkError.message, { variant: "error" });
        }
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = await getToken();

        if (!token) {
            return headers;
        }

        return {
            headers: {
                ...headers,
                authorization: token,
            },
        };
    });

    const httpLinkIam = new BatchHttpLink({
        uri: `${serviceLocationIam}/graphql`,
        credentials: "include",
    });

    const httpLinkCloud = new BatchHttpLink({
        uri: `${serviceLocationCloud}/graphql`,
        credentials: "include",
    });

    const httpLinkTest = new BatchHttpLink({
        uri: `https://data.local.stackables.dev/stackables/prod/system:default`,
        credentials: "include",
    });

    const directionalLink = ApolloLink.split(
        (operation) => operation.getContext().clientName === "iam",
        httpLinkIam,
        ApolloLink.split((operation) => operation.getContext().clientName === "cloud", httpLinkCloud, httpLinkTest)
    );

    const timeoutLink = new ApolloLinkTimeout(10000); // 10 second timeout

    const link = ApolloLink.from([
        retryLink,
        timeoutLink,
        presistedLink,
        errorLink,
        // metricsLink,
        authLink,
        directionalLink,
    ]);

    const client = new ApolloClient({
        cache,
        link: link,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
                nextFetchPolicy: "cache-first",
                errorPolicy: "all",
            },
        },
    });

    return client;
}
