import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app/0-infra";
import "./lib/errorHandler";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// serviceWorkerRegistration.register({
//   onUpdate: registration => {
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//       window.dispatchEvent(new Event('update_available'))
//     }
//   }
// })

// test notification
// setInterval(() => {
//   window.dispatchEvent(new Event('update_available'))
// }, 1000)
