import { useCallback, useEffect, useState } from "react";

export function useLocalStorage<T>(key: string, initialValue: T) {
    const [currentValue, setCurrentValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === key && e.newValue) {
                try {
                    setCurrentValue(JSON.parse(e.newValue));
                } catch (error) {
                    console.log(error);
                }
            }
        };
        window.addEventListener("storage", handler);
        return () => {
            window.removeEventListener("storage", handler);
        };
    });

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(currentValue) : value;
                setCurrentValue(valueToStore);
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.log(error);
            }
        },
        [currentValue, key]
    );
    return [currentValue, setValue] as const;
}
