import cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { errorHandler } from "../../../../lib/errorHandler";

export function getToken() {
    return cookies.get("access-token");
}

export function hasValidToken() {
    const token = getTokenDecoded();
    if (!token) return false;

    if (token.exp > Date.now() / 1000) {
        return true;
    }

    return false;
}

export async function getTokenDigest() {
    const token = getTokenDecoded() as any;

    if (!token) {
        return undefined;
    }

    if (!token.csrf || !token.counter) {
        return undefined;
    }

    return `Cookie/v3 ${token.csrf}`;
}

export interface TokenDecoded {
    [key: string]: string | number;
    exp: number;
}

export function getTokenDecoded(): TokenDecoded | undefined {
    const token = getToken();

    if (!token) {
        return undefined;
    }

    try {
        return jwtDecode(token);
    } catch (e) {
        errorHandler.report(e);
        return undefined;
    }
}
