import React from "react";
import { AuthContext, AuthContextInterface } from "../app/0-infra/contexts/auth/AuthContextProvider";

export function useAuthContext(required = true): AuthContextInterface {
    const user = React.useContext(AuthContext);

    if (!user && required) {
        throw new Error("UserContext not found");
    }

    return user;
}
