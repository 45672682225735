import React, { useMemo, useRef } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { reCaptchaKey } from "../../constants";

interface ReCaptchaProps {
    children: React.ReactNode;
}

export const ReCaptchaContext = React.createContext<
    React.MutableRefObject<number>
>({ current: 0 });

export function ReCaptcha(props: ReCaptchaProps) {
    const sp = useMemo(
        () => ({
            async: true, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: "head" as const, // optional, default to "head", can be "head" or "body",
        }),
        []
    );

    const connected = useRef(0);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={reCaptchaKey}
            useRecaptchaNet={true}
            scriptProps={sp}
        >
            <ReCaptchaContext.Provider value={connected}>
                {props.children}
            </ReCaptchaContext.Provider>
        </GoogleReCaptchaProvider>
    );
}
