import React, { useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { DialogCallbackElement, DialogCallbackInterface } from ".";
import { ActionSheet } from "../../../../components/ActionSheet";
import {
    PageReloadContext,
    PageReloadContextProvider,
} from "../../../../components/PageReload";

export interface DialogContextInterface {
    open: (
        dialog: DialogCallbackElement<any>,
        props: DialogCallbackInterface<any> & { onFinally?: () => void }
    ) => void;
}

export const DialogContext = React.createContext<any>(undefined);

interface DialogContextProviderProps {
    children: React.ReactNode;
}

export function DialogContextProvider(props: DialogContextProviderProps) {
    const [openDialog, setOpenDialog] = useState<
        {
            dialog: DialogCallbackElement<any>;
            props: DialogCallbackInterface<any> & { onFinally?: () => void };
        }[]
    >([]);

    const c = React.useMemo<DialogContextInterface>(() => {
        return {
            open(dialog, hooks) {
                const onClose = () => {
                    hooks.onClose?.();
                    hooks.onFinally?.();
                    setOpenDialog((c) => c.slice(0, c.length - 1));
                };
                const onSubmit = async (v: any) => {
                    await hooks.onSubmit?.(v);
                    hooks.onFinally?.();
                    setOpenDialog((c) => c.slice(0, c.length - 1));
                };

                setOpenDialog((c) => [
                    ...c,
                    { dialog, props: { ...hooks, onClose, onSubmit } },
                ]);
            },
        };
    }, [setOpenDialog]);

    return (
        <DialogContext.Provider value={c}>
            {props.children}
            <ShowDialogs dialogs={openDialog} />
        </DialogContext.Provider>
    );
}

function ShowDialogs(props: {
    dialogs: {
        dialog: DialogCallbackElement<any>;
        props: DialogCallbackInterface<any>;
    }[];
}) {
    return (
        <>
            {props.dialogs.map((render, i) => (
                <PageReloadContextProvider key={i}>
                    <ShowActionSheetWhenReady render={render} />
                </PageReloadContextProvider>
            ))}
        </>
    );
}

function useDebounce2<T>(value: T, delay: number, initial: T) {
    const [debouncedValue, setDebouncedValue] = useState(initial);
    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay);
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
}

export function ShowActionSheetWhenReady({
    render,
}: {
    render: {
        dialog: DialogCallbackElement<any>;
        props: DialogCallbackInterface<any>;
    };
}) {
    const loading = useContextSelector(
        PageReloadContext,
        (state) => state?.loading
    );
    const refreshable = useContextSelector(
        PageReloadContext,
        (state) => state?.refreshable
    );

    const debouncedValue = useDebounce2(!loading || !refreshable, 100, false);

    return (
        <ActionSheet
            open={debouncedValue}
            onOpen={() => {}}
            onClose={() => render.props.onClose?.()}
        >
            {render.dialog(render.props)}
        </ActionSheet>
    );
}
